<template>
  <!-- 行情页 -->
  <div class="pl-3 pr-3 quotes-cantainer" :class="{'mobie':isMobileBol}">
    <!-- 未解锁钱包 -->
    <v-card v-if="!$store.state.isLogin" style="background: #232429; width: 70%; margin: 0 auto">
      <v-container class="mt-0 mb-0 pt-0 pb-0 unlock-wallet-box">
        <v-container class="text-center font-weight-bold btnTextColor--text unlock">
          {{ $t("请先连接您的钱包") }}
        </v-container>
        <v-divider class="light-divider"></v-divider>
        <v-btn @click="handleUnlockWallet" class="mr-1 rounded-lg unlock-button" color="btnColor">
          <span class="btnTextColor--text">{{ $t("解锁钱包") }}</span>
        </v-btn>
      </v-container>
    </v-card>

    <!-- 已解锁钱包 -->
    <v-container v-else style="" class="mt-0 mb-0 white--text">
      <div class="text-center title_text">
        <h4>{{ $t("标的资产价格") }}</h4>
      </div>
      <!-- trade 历史价格 -->
      <div class="trade-top-content">
        <div class="trade-item" v-for="(item, index) in tradePriceInfo" :key="index">
          <div>
            {{ item.tradeType }}: {{ item.tradePrice }}
          </div>
          <v-btn v-if="!isLoadingData" small @click="btcPriceDialog(item)" class="btc-btn">{{ item.label }}</v-btn>
        </div>
      </div>

      <v-btn text color="primary" v-if="isMobileBol" @click="isFoldFilter = !isFoldFilter">
        {{ !isFoldFilter ? $t("收起筛选") : $t("开始筛选") }}
      </v-btn>
      <!-- 筛选 -->
      <v-container :style="{ display: isFoldFilter ? 'none' : 'block',width:'80%' }" fluid>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="3">
            <v-select outlined :items="filterSettleList" :label="$t('结算币')" v-model="filterForm.settleType" dark
              clearable dense>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select outlined :items="filterTradeList" :label="$t('标的资产')" v-model="filterForm.tradeType" dark
              clearable dense>
            </v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select outlined :items="filterLeverageList" :label="$t('杠杆倍数')" v-model="filterForm.leverageLabel" dark
              clearable dense></v-select>
          </v-col>
          <v-col class="d-flex" cols="12" sm="3">
            <v-select outlined :items="filterList" :label="$t('类型')" v-model="filterForm.type" dark clearable dense>
            </v-select>
          </v-col>
        </v-row>
      </v-container>
      <!-- 列表2 -->
      <v-container fluid v-if="!isWrongNet">
        <v-row style="justify-content: center;">
          <v-progress-linear v-if="isLoadingData" indeterminate color="darkBlue darken-2"></v-progress-linear>
          <v-card class="card_content" v-for="(item,index) in tableData" :key="index" :width="isMobileBol?280:250">

            <v-list dense subheader style="background: transparent;">
              <div class="icon_image_content">
                <v-img style="border-radius: 50%;" :src="require(`../assets/currencyType/${item.settleType}.png`)"
                  max-height="50" max-width="50">
                </v-img>
              </div>
              <v-list-item style="margin-top:25px">
                <v-list-item-content>
                  <v-list-item-title class="label-text">{{$t('结算币')}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="value-text">
                  {{ item.settleType }}
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="label-text">{{$t('标的资产')}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="value-text">
                  {{ item.tradeType }}
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="label-text">{{$t('杠杆倍数')}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="value-text">
                  <div>
                    {{ item.leverageLabel }}
                  </div>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="label-text">{{$t('类型')}}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <div v-if="item.type === '0'" class="text-center darkBlue--text">
                    {{ $t("熊证") }}
                  </div>
                  <div v-else class="text-center darkPink--text">
                    {{ $t("牛证") }}
                  </div>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="label-text">

                    {{$t('权证价格')}}
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on" class="mdi mdi-help-circle-outline mdi-18px"></span>
                      </template>
                      <span>{{ $t("价格在0~2之间变化") }} </span>
                    </v-tooltip>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="value-text">
                  {{ item.cbbcprice }}
                </v-list-item-icon>
              </v-list-item>

            </v-list>

            <v-divider style="margin-top:50px"></v-divider>
            <v-container class="card_footer">
              <v-btn text style="width:50%" :loading="item.isRebaseLoading" @click="handleRebase(item)"> <span
                  class="text1">rebase</span>
              </v-btn>
              <v-divider vertical></v-divider>
              <v-btn text style="width:50%" @click="openCppcDetailShow(item)"> <span class="text1">{{ $t("详情") }}</span>
              </v-btn>
            </v-container>
          </v-card>
        </v-row>
      </v-container>
      <h4 v-else style="text-align: center">{{ $t("错误网络") }}</h4>
    </v-container>
    <!-- BTC历史价格弹窗 -->
    <v-dialog v-model="isBtcPriceDialog" overlay-color="#202e8d" :width="isMobileBol ? '' : '620px'">
      <v-card style="background: #202e8d" max-width="620">
        <v-alert dense type="error" v-if="noChartData">
          {{$t("暂无数据")}}
        </v-alert>
        <div style="width: 100%; overflow: auto">
          <!-- 顶部 -->
          <v-container class="text-center font-weight-bold white--text text-h6" style="color:#fff">
            <span> {{ currentTrade.tradeType }} {{ $t("价格") }} </span>
            <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="isBtcPriceDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-container>

          <!-- 折线图 -->
          <v-container>
            <v-progress-linear :active="lineLoading" bottom :indeterminate="lineLoading"></v-progress-linear>
            <div v-if="isBtcPriceDialog" id="trade-line" :style="{
              width: isMobileBol ? '100%' : '100%',
              height: '400px',
            }"></div>
          </v-container>

          <!-- 底部按钮 -->
          <div style="margin: 4px auto; display: flex; justify-content: center">
            <v-btn-toggle tile background-color="#424d5f" color="accent-3" v-model="tradeTimeType"
              @change="btcTimeTypeChange">
              <v-btn style="background:transparent" value="hour">
                <span style="color:#fff" class="hidden-sm-and-down">{{ $t("最近1小时") }}</span>
              </v-btn>
              <v-btn style="background:transparent" value="day">
                <span style="color:#fff" class="hidden-sm-and-down"> {{ $t("最近24小时") }}</span>
              </v-btn>
              <v-btn style="background:transparent" value="mouth">
                <span style="color:#fff" class="hidden-sm-and-down"> {{ $t("最近3天") }}</span>
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- cppc历史价格 ，市值弹窗 -->
    <v-dialog v-model="isCppcDetailDialog" overlay-color="#202e8d" :width="isMobileBol ? '1020px' : '1020px'">
      <v-card style="background: #202e8d" width="1020">
        <v-container class="text-center font-weight-bold white--text text-h6">
          <span>{{ currentItem ? currentItem.name : "" }}</span>
          <v-btn style="position: absolute; right: 2px; top: 0px" icon dark @click="isCppcDetailDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
        <div style="width: 100%; overflow: auto">
          <v-container class="pl-5 pr-5">
            <v-progress-linear :active="lineLoading" bottom :indeterminate="lineLoading"></v-progress-linear>
            <v-alert dense type="error" v-if="noChartData">
              {{$t("暂无数据")}}
            </v-alert>
            <div :class="[cbbcDialogType]">
              <div v-if="isCppcDetailDialog" id="cbbc-price-line" :style="{
                width: isMobileBol ? '100%' : '100%',
                height: '400px',
              }"></div>
              <div v-if="isCppcDetailDialog" id="cbbc-market-line" :style="{
                width: isMobileBol ? '100%' : '100%',
                height: '400px',
              }"></div>
            </div>
          </v-container>
          <div style="margin: 4px auto; display: flex; justify-content: center">
            <v-btn-toggle tile background-color="#424d5f" color="accent-3" v-model="cbbcTimeType"
              @change="__getCbbcInfo">
              <v-btn style="background:transparent" value="hour">
                <span style="color:#fff" class="hidden-sm-and-down">{{ $t("最近1小时") }}</span>
              </v-btn>
              <v-btn style="background:transparent" value="day">
                <span style="color:#fff" class="hidden-sm-and-down"> {{ $t("最近24小时") }}</span>
              </v-btn>
              <v-btn style="background:transparent" value="mouth">
                <span style="color:#fff" class="hidden-sm-and-down"> {{ $t("最近3天") }}</span>
              </v-btn>
            </v-btn-toggle>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from '../utils/request.js'

import eventBus from '../utils/eventBus'
import { mapState, mapMutations } from 'vuex'
import helper from '../helpers'
import { getNetworkConfig } from '../chainNetConfig.js'

export default {
  name: 'Quotes',
  data() {
    return {
      noChartData: false,

      tableData: [], // 列表数据
      currentItem: null, // 当前行
      originalData: [],
      isLoadingData: false,

      filterList: [this.$t('牛证'), this.$t('熊证')], // 类型LIST
      filterSettleList: [], // 币种LIST
      filterTradeList: [], // 标的LIST
      filterLeverageList: ['1X', '2X', '3X', '5X', '10X', '20X', '50X', '100X', '150X', '200X', '250X'], // 杠杆list

      isFoldFilter: false,
      isBtcPriceDialog: false, // btc 历史价格弹窗
      tradePriceLineData: [], // btc历史价格数据
      tradeTimeType: 'hour', // 折线图时间类型
      lineLoading: false,

      isCppcDetailDialog: false,
      cbbcPriceData: [], // 历史权证价格
      cbbcMarketValData: [], // 历史权证市值
      cbbcTimeType: 'hour', // 折线图时间类型
      tradePriceInfo: [],
      currentTrade: '',
      formatType: {
        hour: 'YYYY-MM-DD  HH:mm',
        day: 'YYYY-MM-DD  HH:00',
        mouth: 'YYYY-MM-DD',
        year: 'YYYY-MM-DD',
        all: 'YYYY-MM-DD'
      },
      isWrongNet: false, // 是否是错误网络
      currentLang: 'zhHans',
      filterForm: { // 筛选form
        type: '', // 类型
        settleType: '', // 币种
        tradeType: '', // 标的
        leverageLabel: '' // 杠杆
      }
    }
  },
  watch: {
    defaultAccount() {
      this.getQuotesList()
    },
    currentNetworkConfig: {
      handler(val) {
        this.isWrongNet = !val
      },
      deep: true
    },
    filterForm: {
      handler(val) {
        this.tableData = this.originalData.filter(e => {
          const filterType = this.getFilterType(this.filterForm.type)
          const a = !filterType || (filterType && filterType === e.type)
          const b = !val.settleType || (e.name.includes(val.settleType))
          const c = !val.tradeType || (e.name.includes(val.tradeType))
          const d = !val.leverageLabel || (e.name.includes(val.leverageLabel))
          return a && b && c && d
        })
      },
      deep: true
    }
  },
  computed: {
    cbbcDialogType: function () { // pc 移动端判断
      return this.isMobileBol ? 'mobile-cbbc-dialog' : 'pc-cbbc-dialog'
    },

    ...mapState(['isMobileBol', 'defaultAccount', 'currentNetworkConfig'])
  },
  created() {
    this.isFoldFilter = this.isMobileBol
    this.currentLang = localStorage.getItem('language')
    this.getQuotesList()
    this.getFilterData()
    this.getTradePriceInfo()
    this.$nextTick(() => {
      this.isWrongNet = !this.currentNetworkConfig
    })
  },
  methods: {
    ...mapMutations(['upPendingStatus']),
    async getTradePriceInfo() { // 获取当前网络下，各标的物价格
      const config = await getNetworkConfig()
      const tradeTypeList = config.tradeType
      const tradePriceInfo = await helper.getTradePrice()
      if (tradePriceInfo.code === 0) {
        for (let i = 0; i < tradePriceInfo.data.length; i++) {
          const item = tradePriceInfo.data[i]
          if (tradeTypeList.indexOf(item.tradeType) !== -1) {
            this.tradePriceInfo.push({
              tradePrice: helper.toEth(item.tradePrice),
              tradeType: item.tradeType,
              label: this.$t('历史价格')
            })
          }
        }
      }
    },
    getFilterType(type) { // 兼容英文 下的搜索 待优化
      if (!type) {
        return null
      }
      if (this.currentLang === 'en') {
        return type === 'Bull' ? '1' : '0'
      } else {
        return type === '牛证' ? '1' : '0'
      }
    },
    handleUnlockWallet() {
      eventBus.$emit('handleUnlockWallet')
    },
    async getQuotesList() {
      if (!this.defaultAccount) {
        return
      }
      const aa = new Date().getTime()

      this.isLoadingData = true
      const list = await helper.getCbbcTypeInfo(this.defaultAccount)
      //console.log(list, 'list66')
      //this.tableData = list.map((i) => { return { ...i, isRebaseLoading: false } })
      this.tableData = JSON.parse(JSON.stringify(list))
      this.originalData = JSON.parse(JSON.stringify(list))

      this.isLoadingData = false

      const bb = new Date().getTime()
      console.log(bb - aa, 'getQuotesList----耗时计算')
    },
    handleRebase(item) {
      item.isRebaseLoading = true
      helper.rebase(item.Bytes32, item.tradeType, this.defaultAccount,
        (hash) => {
          console.log(hash, 'hash6666')
          // if (hash === null) { // 点击了确认

          // } else {
          // }
          item.isRebaseLoading = false
        },
        async () => {
          this.upPendingStatus(true)
          this.getQuotesList()
        })
    },
    async getFilterData() { // 获取筛选项list
      const settleToken = await helper.getSettleTokenListByConfig()
      const tradeToken = await helper.getTradeTokenListByConfig()
      for (let i = 0; i < settleToken.length; i++) {
        this.filterSettleList.push(settleToken[i].name)
      }
      for (let i = 0; i < tradeToken.length; i++) {
        this.filterTradeList.push(tradeToken[i].name)
      }
    },
    btcPriceDialog(item) { // btc或UNI标的物 历史价格弹窗
      this.isBtcPriceDialog = true
      this.tradeTimeType = 'hour'
      this.currentTrade = item
      this.__getTradeHistoricalPrice()
    },
    btcTimeTypeChange() {
      this.__getTradeHistoricalPrice()
    },
    __getTradeHistoricalPrice() { // 获取标的物 历史价格
      this.lineLoading = true
      this.noChartData = false
      this.tradePriceLineData = []
      axios.get(`/common/btcPrice/${this.tradeTimeType}/${this.currentTrade.tradeType}`).then((res) => {
        if (res.code === 1) {
          console.log(res, '99')
          if (res.data.length === 0) {
            this.lineLoading = false
            this.noChartData = true
            return
          }
          this.tradePriceLineData = res.data.map((i) => { return { ...i, value: Number(i.price).toFixed(4) } })
          this.$nextTick(() => {
            this.initTradePriceLine()
            this.lineLoading = false
            this.noChartData = false
          })
        }
      }).catch((err) => {
        console.error(err)
        this.lineLoading = false
        this.noChartData = true
      })
    },

    openCppcDetailShow(item) { // 历史权证价格 市值弹窗
      this.isCppcDetailDialog = true
      this.currentItem = item
      // console.log(item, 'item')
      this.cbbcTimeType = 'hour'
      this.__getCbbcInfo()
    },
    __getCbbcInfo() { // 获取历史权证价格和市值
      this.lineLoading = true
      this.noChartData = false
      this.cbbcPriceData = []
      this.cbbcMarketValData = []
      axios.get(`/common/cbbcPriceRecods/${this.currentItem.address}/${this.cbbcTimeType}`).then((res) => {
        if (res.code === 1) {
          console.log(res, '99')
          if (res.data.length === 0) {
            this.lineLoading = false
            this.noChartData = true
            return
          }
          this.cbbcPriceData = res.data.map((i) => { return { ...i, value: Number(i.price).toFixed(4) } })// 价格
          this.cbbcMarketValData = res.data.map((i) => { return { ...i, value: (Number(i.price) * Number(i.quantity)).toFixed(4) } })// 市值
          this.$nextTick(() => {
            this.initCbbcLine()
            this.initCbbcMarketValLine()
            this.lineLoading = false
            this.noChartData = false
          })
        }
      }).catch((err) => {
        console.error(err)
        this.lineLoading = false
        this.noChartData = true
      })
    },
    initTradePriceLine() { // 标的物 历史价格 折线图
      const _this = this
      const chartDom = document.getElementById('trade-line')
      const myChart = this.$echarts.init(chartDom)
      const minVal = JSON.parse(JSON.stringify(this.tradePriceLineData)).sort((a, b) => { return a.value - b.value })[0].value
      const maxVal = JSON.parse(JSON.stringify(this.tradePriceLineData)).sort((a, b) => { return b.value - a.value })[0].value
      const option = {
        grid: {
          top: '8%',
          left: '16%', // 原来是10%，修改为20%
          right: '8%',
          bottom: '8%'
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          textStyle: {
            color: '#1F2B4D'
          },
          axisPointer: {
            type: 'line',
            animation: true,
            label: {
              backgroundColor: '#fff',
              borderColor: '#aaa',
              borderWidth: 1,
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              color: '#1F2B4D'
            }
          },
          formatter: function (params) {
            console.log(params[0], 'params[0]')
            const a = params[0]
            return `<p> ${_this.$t('价格')}：${a.data.value}</p>
          <p>${_this.$t('时间')}：${a.data.date}</p>
             <p>${_this.$t('交易所')}：${a.data.source}</p>`
          }
        },
        xAxis: {
          type: 'category',
          data: this.tradePriceLineData.map((item) => { return item.date }),
          axisLabel: {
            textStyle: {
              color: '#c3c5cb'
            }
            // formatter: function (val) {
            //   const res = new Date(Number(val))
            //   return _this.$moment(res).format(_this.formatType[_this.tradeTimeType])
            // }
          }
        },
        dataZoom: [{
          type: 'inside',
          show: true
        }],
        yAxis: {
          type: 'value',
          // axisLine: {
          //   show: true // 轴线
          // },
          min: Math.floor(minVal) - 50 < 0 ? 0 : Math.floor(minVal) - 50, // y轴的最小值
          max: Math.ceil(maxVal) + 50, // y轴最大值
          splitLine: { // 分割线
            show: false,
            lineStyle: {
              color: '#fff'
            }
          },

          // splitNumber: 10,
          // minInterval: 1000,
          axisLabel: {
            textStyle: {
              color: '#c3c5cb'
            }
          }
        },
        series: [{
          data: this.tradePriceLineData,
          type: 'line'
        }]
      }
      console.log(option, 'option')

      option && myChart.setOption(option)
    },
    initCbbcLine() { // 权证历史价格 折线图
      const _this = this
      const chartDom = document.getElementById('cbbc-price-line')
      const myChart = this.$echarts.init(chartDom)
      const minVal = JSON.parse(JSON.stringify(this.cbbcPriceData)).sort((a, b) => { return a.value - b.value })[0].value
      const maxVal = JSON.parse(JSON.stringify(this.cbbcPriceData)).sort((a, b) => { return b.value - a.value })[0].value
      const option = {
        title: {
          text: this.$t('价格'),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.7)'
          }
        },
        grid: {
          top: '12%',
          left: '20%', // 原来是10%，修改为20%
          right: '8%',
          bottom: '8%'
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          textStyle: {
            color: '#1F2B4D'
          },
          axisPointer: {
            type: 'line',
            animation: true,
            label: {
              backgroundColor: '#fff',
              borderColor: '#aaa',
              borderWidth: 1,
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              color: '#1F2B4D'
            }
          },
          formatter: function (params) {
            const a = params[0]
            return `<p> ${_this.$t('价格')}：${a.data.value}</p>
             <p>${_this.$t('时间')}：${a.data.date}</p>
          `
          }
        },
        xAxis: {
          type: 'category',
          data: this.cbbcPriceData.map((item) => { return item.date }),

          axisLabel: {
            textStyle: {
              color: '#c3c5cb'
            }
            // formatter: function (val) {
            //   const res = new Date(Number(val))
            //   return _this.$moment(res).format(_this.formatType[_this.cbbcTimeType])
            // }
          }
        },
        dataZoom: [{
          type: 'inside',
          show: true
        }],
        yAxis: {
          type: 'value',
          // axisLine: {
          //   show: true // 轴线
          // },
          splitLine: { // 分割线
            show: false,
            lineStyle: {
              color: '#fff'
            }
          },
          min: Math.floor(minVal), // y轴的最小值
          max: Math.ceil(maxVal), // y轴最大值
          // minInterval: 0.01,
          axisLabel: {
            textStyle: {
              color: '#c3c5cb'
            }
          }
        },
        series: [{
          data: this.cbbcPriceData,
          type: 'line'
        }]
      }

      option && myChart.setOption(option)
    },
    initCbbcMarketValLine() { // 权证历史市值 折线图
      const _this = this
      const chartDom = document.getElementById('cbbc-market-line')
      const myChart = this.$echarts.init(chartDom)
      const minVal = JSON.parse(JSON.stringify(this.cbbcMarketValData)).sort((a, b) => { return a.value - b.value })[0].value
      const maxVal = JSON.parse(JSON.stringify(this.cbbcMarketValData)).sort((a, b) => { return b.value - a.value })[0].value
      const option = {
        title: {
          text: this.$t('市值'),
          textStyle: {
            color: 'rgba(255, 255, 255, 0.7)'
          }
        },
        grid: {
          top: '12%',
          left: '20%', // 原来是10%，修改为20%
          right: '8%',
          bottom: '8%'
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          textStyle: {
            color: '#1F2B4D'
          },
          axisPointer: {
            type: 'line',
            animation: true,
            label: {
              backgroundColor: '#fff',
              borderColor: '#aaa',
              borderWidth: 1,
              shadowBlur: 0,
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              color: '#1F2B4D'
            }
          },
          formatter: function (params) {
            const a = params[0]
            return `<p> ${_this.$t('市值')}：${a.data.value}</p >
    <p>${_this.$t('时间')}：${a.data.date}</p>
            `
          }
        },
        xAxis: {
          type: 'category',
          data: this.cbbcMarketValData.map((item) => { return item.date }),
          axisLabel: {
            textStyle: {
              color: '#c3c5cb'
            }
            // formatter: function (val) {
            //   const res = new Date(Number(val))
            //   return _this.$moment(res).format(_this.formatType[_this.cbbcTimeType])
            // }
          }
        },
        dataZoom: [{
          type: 'inside',
          show: true
        }],
        yAxis: {
          type: 'value',
          // axisLine: {
          //   show: true // 轴线
          // },
          splitLine: { // 分割线
            show: false,
            lineStyle: {
              color: '#fff'
            }
          },
          min: Math.ceil(minVal), // y轴的最小值
          max: Math.ceil(maxVal), // y轴最大值
          axisLabel: {
            textStyle: {
              color: '#c3c5cb'
            }
          }
        },
        series: [{
          data: this.cbbcMarketValData,
          type: 'line'
        }]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-application .hidden-sm-and-down {
  display: block !important;
}

.quotes-cantainer {
  width: 84%;
  min-height: 730px;
  overflow: auto;
  margin: 0 auto;
  margin-top: 50px;

  &.mobie {
    width: 100%;
    padding: 0 !important;
    margin-top: 20px;
  }

  .unlock-wallet-box {
    min-height: calc(100vh - 300px);
    position: relative;

    >button {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .card_content {
    margin: 30px 15px;
    background-image: url("../assets/card-bg.png");
    background-size: 70%;

    .label-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
      font-weight: 400;
      text-align: left;
      color: #283f91;
    }

    .value-text {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #283f91;
    }

    .icon_image_content {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: -30px;
      width: 100%;
    }
  }

  .card_footer {
    display: flex;
    justify-content: space-around;
    padding: 0 0;

    .text1 {
      font-size: 14px;
      font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
      font-weight: 700;
      text-align: left;
      color: #1e1e5d;
    }
  }
}

.trade-top-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;

  .trade-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8px;

    >span {
      font-size: 16px;
    }

    .btc-btn {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
      min-width: 50px;
      height: 20px;
      line-height: 20px;
      background: #1e1e5d;
      font-size: 12px;
      color: #6da8ff;
      margin: 0 4px;
    }
  }
}

.title_text {
  color: #fff;
  font-size: 180% !important;
}

.icon_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10% !important;
  margin-bottom: 5% !important;
}

.light-divider {
  color: #707070;
  border-color: #707070 !important;
}

.unlock {
  padding-top: 20px !important;
}

.unlock-button {
  margin-top: 20px;
}

// #trade-line {
//   width: 580px;
//   height: 400px;
// }
.pc-cbbc-dialog {
  display: flex;
  justify-content: space-between;
}

.mobile-cbbc-dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
