// 封装axios请求pm2 restart  --max-memory-restart 800M
import axios from 'axios' // 引入axios

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests 默认false
  timeout: 60000 // request timeout,
})

// request interceptor
service.interceptors.request.use(
  config => {
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    return response.data // data 相当于后端 body
  },
  error => { // 返回status 非200
    // console.log(error, '6666666')
    return Promise.reject(error)
  }
)

export default service
